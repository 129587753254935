$red: #f94144;
$orange: #f3722c;
$carrotOrange: #f8961e;
$coral: #f9844a;
$saffron: #f9c74f;
$pistachio: #90be6d;
$zomp: #43aa8b;
$darkCyan: #4d908e;
$paynesGray: #577590;
$curulean: #277da1;
$gray: #6c757d;
$white: #ffffff;
$darkGray: #8a817c;

:export {
	red: $red;
	orange: $orange;
	carrotOrange: $carrotOrange;
	coral: $coral;
	saffron: $saffron;
	pistachio: $pistachio;
	zomp: $zomp;
	darkCyan: $darkCyan;
	paynesGray: $paynesGray;
	curulean: $curulean;
}
