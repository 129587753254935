/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width: 320px) {
	.navigation-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;

		.header {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			width: 100%;

			.name-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				z-index: -1;
				margin-right: 30px;

				.period {
					height: 5px;
					width: 5px;
					border-radius: 50%;
					background-color: $red;
					position: relative;
					top: 10px;
					right: 2px;
				}
			}

			.hamburger-wrapper {
				display: block;
				position: relative;
				top: 29px;
				left: 30px;
				z-index: 1;
				display: block;
				cursor: pointer;

				&.show-menu {
					span {
						background: $red;
					}

					.first {
						opacity: 1;
						transform: rotate(45deg) translate(-3px, -2px);
					}

					.second {
						opacity: 0;
						transform: rotate(0deg) scale(0.2, 0.2);
					}

					.third {
						transform: rotate(-45deg) translate(0, -1px);
					}
				}

				span {
					display: block;
					width: 33px;
					height: 4px;
					margin-bottom: 5px;
					position: relative;

					background: #cdcdcd;
					border-radius: 3px;

					z-index: 1;

					transform-origin: 4px 0px;

					transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
						background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

					&:first-child {
						transform-origin: 0% 0%;
					}

					&:last-child {
						transform-origin: 0% 100%;
					}
				}

				a {
					text-decoration: none;
					color: #232323;
					transition: color 0.3s ease;
				}
			}

			.menu-wrapper {
				position: absolute;
				width: 100%;
				margin: -100px 0 0 -50px;
				padding: 50px;
				padding-top: 200px;

				background: #ededed;
				list-style-type: none;
				-webkit-font-smoothing: antialiased;

				transform-origin: 0% 0%;
				transform: translate(-100%, 0);

				transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
			}

			.menu-wrapper li {
				padding: 10px 0;
				font-size: 22px;
				list-style: none;

				a {
					text-decoration: none;
					color: #6c757d;
				}
			}

			.menu-wrapper {
				&.show-menu {
					transform: none;
				}
			}
		}
	}
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media (min-width: 600px) {
	.navigation-wrapper {
		.header {
			width: auto;
		}

		.actions {
			margin-right: auto;
			position: relative;
			left: 35px;
		}

		.contact-icons {
			display: flex;
			align-items: center;
			.icon {
				padding: 0 10px;
				display: inline;
				cursor: pointer;

				&:hover {
					filter: brightness(85%);
				}

				transition: filter 200ms ease-in;

				svg {
					height: 35px;
					width: 35px;
					fill: $red;
					&.resume-icon {
						height: 31px;
						width: 31px;
						& path {
							stroke: $red;
						}
					}
				}
			}
		}
	}
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 801px) {
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
}
