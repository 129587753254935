.modal-overlay {
	display: none;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
}

.modal-overlay.active {
	display: flex;

	.modal-dialog {
		filter: drop-shadow(-15px 18px 40px rgba(0, 0, 0, 0.5));
		position: relative;
		max-height: 600px;
		overflow: scroll;
	}
}

.modal-overlay {
	.modal-dialog {
		display: relative;

		.modal-header {
			display: flex;

			.close {
				position: absolute;
				top: 20px;
				right: 20px;
				cursor: pointer;
				height: 20px;
				width: 20px;
			}
		}

		background-color: white;
		position: relative;
		max-width: 600px;
		width: 75%;
		padding: 30px;

		.modal-content {
			display: flex;
			flex-direction: column;
		}
	}
}