@media (min-width: 320px) {
	.project-copy {
		width: 90%;
		margin: auto;
		text-align: center;
	}

	.tiles {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}

	.pill-wrapper {
		flex-flow: row wrap;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.project-copy {
	width: 55%;
}
