@media (min-width: 320px) {
    .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        cursor: pointer;
        flex-flow: row wrap;
        margin-bottom: 40px;
    }
}