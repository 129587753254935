@media (min-width: 320px) {
	.about-me-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.about-me-content {
			width: 90%;
			text-align: center;
			margin: 25px 0;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
		}

		.jump-link svg {
			height: 55px;
			width: 55px;
			transform: rotate(180deg);
			color: $red;

			&:hover {
				filter: brightness(85%);
			}

			transition: filter 200ms ease-in;
		}
	}
}

@media (min-width: 600px) {
	.about-me-wrapper {
		// height: 90vh;

		.about-me-content {
			width: 45%;
		}
	}
}