.form {
	display: flex;
	flex-direction: column;

	h1 {
		margin-bottom: 0;
	}

	.form-body {
		display: flex;
		flex-direction: column;

		.field {
			margin: 10px 0;

			.error {
				color: $red;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				padding: 5px 0;
				position: relative;
				top: 4px;
			}
		}

	}
}