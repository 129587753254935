@import "../mixins/breakpoints";

.avatar {
	height: 300px;
	width: 300px;
	overflow: hidden;
	border-radius: 50%;
	border: 5px solid $red;

	.avatar-img {
		background-image: url("../../assets/images/jill.jpg");
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

@include xs {
	.avatar {
		height: 225px;
		width: 225px;
	}
}

@include sm {
	.avatar {
		height: 400px;
		width: 400px;
	}
}