$breakpoints: (
	"xs": 0,
	"sm": 576px,
	"md": 768px,
	"lg": 992px,
	"xl": 1200px,
	"xxl": 1600px,
);

@mixin xs {
	@media (min-width: map-get($breakpoints, "xs")) {
		@content;
	}
}

@mixin sm {
	@media (min-width: map-get($breakpoints, "sm")) {
		@content;
	}
}

@mixin md {
	@media (min-width: map-get($breakpoints, "md")) {
		@content;
	}
}

@mixin lg {
	@media (min-width: map-get($breakpoints, "lg")) {
		@content;
	}
}

@mixin xl {
	@media (min-width: map-get($breakpoints, "xl")) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: map-get($breakpoints, "xxl")) {
		@content;
	}
}

@mixin breakpoint($bp: 0) {
	@media (max-width: $bp) {
		@content;
	}
}